import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
@Injectable()
export class UrlSerializerService implements UrlSerializer {
  private defaultSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
    if (url.indexOf('Airframe') > -1 || url.indexOf('Procedure') > -1 || url.indexOf('Engine') > -1) {
      if (url.indexOf('(') > -1) {
        url = url.replace(/\(/g, '%28').replace(/\)/g, '%29');
        return this.defaultSerializer.parse(url);
      } else {
        return this.defaultSerializer.parse(url);
      }
    } else {
      return this.defaultSerializer.parse(url);
    }
  }

  serialize(tree: UrlTree): string {
    return this.defaultSerializer
      .serialize(tree)
      .replace(/%28/g, '(')
      .replace(/%29/g, ')');
  }
}
