import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import fleet2Manual from './resources/fleet2manual.json';
import {
  libraryAndPublication,
  Map,
} from '../interfacesUtil';
import fleetFromDescription from './resources/fleetFromDescription.json';

const fleetWithLibratyAndPublication: libraryAndPublication = fleet2Manual;

const feeltWithDescription: Map = fleetFromDescription;
@Component({
  selector: 'app-engine',
  templateUrl: './engine.component.html',
  styleUrls: ['./engine.component.css']
})
export class EngineComponent implements OnInit {
  displayNotFoundError = false;
  engineNotFound= '';
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((data) => {
      const engineParam = data['engine'];
      const filterAttrValuesParam = data['manualType'];
      const noseNumber = data['noseNumber'];
      var routeUrlObject = this.getFleetURL(
        engineParam,
        filterAttrValuesParam,
        noseNumber
      );
      var routeUrl = routeUrlObject ? this.getURL(routeUrlObject) : null;
      console.log(routeUrl);
      if (routeUrl != null) {
        this.redirect(routeUrl);
      } else {
        this.displayNotFoundError = true;
        this.engineNotFound = engineParam;
      }
    });
  }

  redirect(routeUrl: string) {
    window.location.href = routeUrl;
  }

  getURL(routeUrlObject: any) {
    let queryParams: Map = {
      library: routeUrlObject.fleetId,
      publicationID: routeUrlObject.publicationID
    };
    if(routeUrlObject.filterAttrValues) {
      queryParams['filterAttr'] = 'CEC';
      queryParams['filterAttrValues'] = routeUrlObject.filterAttrValues;
    }
    const url = new URL(environment.basePath, environment.baseURL);
    const urlSearchParams = new URLSearchParams();
    Object.keys(queryParams).forEach((key) =>
      urlSearchParams.set(key, queryParams[key])
    );
    return url.toString() + '?' + urlSearchParams.toString();
  }

  getFleetURL(fleet: string, filterAttrValues: any, noseNumber: any) {
    const key = this.getFleet(fleet);
    if (fleetWithLibratyAndPublication[key]) {
      return {
        fleetId: fleetWithLibratyAndPublication[key].Fleet_ID,
        publicationID:
          fleetWithLibratyAndPublication[key].ManualType[filterAttrValues],
        filterAttrValues: noseNumber,
        oldManual:
          fleetWithLibratyAndPublication[key].OldManuals[filterAttrValues],
        fleetName: key,
      };
    }
    return null;
  }

  getFleet(value: string) {
    return feeltWithDescription[value] ? feeltWithDescription[value] : value;
  }
}


