export const environment = {
  production: false,
  baseURL: 'https://uat.aa-stage.flatironscloud.com/',
  oldBaseURL: 'https://aaemanuals.aviationzone.net',
  basePath: 'pinpoint/#/main/goto',
  oldBasePath:'ietpata/exec/manual.aspx',
  uri: 'http://localhost:4200',
  flagsEnv: 'local',
  prevUri: null,
  federatedGatewayUrl: 'https://graph-np.aa.com/graphql',
  pingAuthorityUrl: 'https://idpstage.aa.com',
  pingClientID: 'eManuals-169892-stage-Ktogn'
};
