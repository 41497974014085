import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import fleet2Manual from './resources/fleet2manual.json';
import {
  libraryAndPublication,
  Map,
  excludedLibraries,
} from '../interfacesUtil';
import fleetFromDescription from './resources/fleetFromDescription.json';
import readyFleetsJson from './resources/ready_fleets.json';
import excludeFleetByCondition from './resources/excludeFleetByCondition.json';

const fleetWithLibratyAndPublication: libraryAndPublication = fleet2Manual;
const fleetsExcludedLibraries: excludedLibraries = excludeFleetByCondition;

const feeltWithDescription: Map = fleetFromDescription;
@Component({
  selector: 'app-airframe',
  templateUrl: './airframe.component.html',
  styleUrls: ['./airframe.component.css']
})
export class AirframeComponent implements OnInit {
  displayNotFoundError = false;
  fleetNotFound= '';
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((data) => {
      const fleetParam = data['fleet'];
      const filterAttrValuesParam = data['manualType'];
      const noseNumber = data['noseNumber'];
      var routeUrlObject = this.getFleetURL(
        fleetParam,
        filterAttrValuesParam,
        noseNumber
      );
      var routeUrl = routeUrlObject
        ? readyFleetsJson.some((element) => element === this.getFleet(fleetParam))
          ? this.isFleetExcluded(routeUrlObject, filterAttrValuesParam)
          : this.getOldURL(routeUrlObject)
        : null;
      console.log(routeUrl);
      if (routeUrl != null) {
        this.redirect(routeUrl);
      } else {
        this.displayNotFoundError = true;
        this.fleetNotFound = fleetParam;
      }
    });
  }

  private isFleetExcluded(
    routeUrlObject: {
      fleetId: string;
      publicationID: string;
      filterAttrValues: any;
      oldManual: { DOCTYPE: string; DOCNBR: string };
      fleetName: string;
    },
    filterAttrValuesParam: any
  ) {
    const fleetShouldBeExcluded = fleetsExcludedLibraries[
      routeUrlObject.fleetName
    ]
      ? fleetsExcludedLibraries[routeUrlObject.fleetName].ExcludedManuals.some(
          (element) => element === filterAttrValuesParam
        )
      : false;
    return fleetShouldBeExcluded
      ? this.getOldURL(routeUrlObject)
      : this.getURL(routeUrlObject);
  }

  redirect(routeUrl: string) {
    window.location.href = routeUrl;
  }

  getURL(routeUrlObject: any) {
    let queryParams: Map = {
      library: routeUrlObject.fleetId,
      publicationID: routeUrlObject.publicationID
    };
    if(routeUrlObject.filterAttrValues) {
      queryParams['filterAttr'] = 'CEC';
      queryParams['filterAttrValues'] = routeUrlObject.filterAttrValues;
    }
    const url = new URL(environment.basePath, environment.baseURL);
    const urlSearchParams = new URLSearchParams();
    Object.keys(queryParams).forEach((key) =>
      urlSearchParams.set(key, queryParams[key])
    );
    return url.toString() + '?' + urlSearchParams.toString();
  }

  getOldURL(routeUrlObject: any) {
    let queryParams: Map = {
      VERSION: 'current',
      MODEL: routeUrlObject.fleetName,
      DOCTYPE: routeUrlObject.oldManual['DOCTYPE'],
      DOCNBR: routeUrlObject.oldManual['DOCNBR'],
      ISESSION: '1',
    };
    if(routeUrlObject.filterAttrValues) {
      queryParams['EFFECT'] = routeUrlObject.filterAttrValues;
    }
    const url = new URL(environment.oldBasePath, environment.oldBaseURL);
    const urlSearchParams = new URLSearchParams();
    Object.keys(queryParams).forEach((key) =>
      urlSearchParams.set(key, queryParams[key])
    );
    return url.toString() + '?' + urlSearchParams.toString();
  }

  getFleetURL(fleet: string, filterAttrValues: any, noseNumber: any) {
    const key = this.getFleet(fleet);
    if (fleetWithLibratyAndPublication[key]) {
      return {
        fleetId: fleetWithLibratyAndPublication[key].Fleet_ID,
        publicationID:
          fleetWithLibratyAndPublication[key].ManualType[filterAttrValues],
        filterAttrValues: noseNumber,
        oldManual:
          fleetWithLibratyAndPublication[key].OldManuals[filterAttrValues],
        fleetName: key,
      };
    }
    return null;
  }

  getFleet(value: string) {
    return feeltWithDescription[value] ? feeltWithDescription[value] : value;
  }
}

