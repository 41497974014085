import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';

import { RouterModule, Routes, UrlSerializer } from '@angular/router';
import { PingAuthenticationModule } from '@techops-ui/ping-authentication';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { PingAuthGuard } from '@techops-ui/ping-authentication';
import { HomeComponent } from './home/home.component';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { UrlSerializerService } from './custom-url-handling-strategy';
import { AirframeComponent } from './airframe/airframe.component';
import { ProcedureComponent } from './procedure/procedure.component';
import { EngineComponent } from './engine/engine.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [PingAuthGuard],
  },
  {
    path: 'Airframe/:manualType/:fleet/:noseNumber',
    component: AirframeComponent,
    canActivate: [PingAuthGuard],
  },
  {
    path: 'Airframe/:manualType/:fleet',
    component: AirframeComponent,
    canActivate: [PingAuthGuard],
  },
  {
    path: 'Procedure/:manual',
    component: ProcedureComponent,
    canActivate: [PingAuthGuard],
  },
  {
    path: 'Engine/:manualType/:engine',
    component: EngineComponent,
    canActivate: [PingAuthGuard],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AirframeComponent,
    ProcedureComponent,
    EngineComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    PingAuthenticationModule.forRoot({
      authority: environment.pingAuthorityUrl,
      client_id: environment.pingClientID,
    }),
    RouterModule.forRoot(routes, { useHash: false }),
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: UrlSerializer, useClass: UrlSerializerService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
