import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import fleet2Manual from './resources/fleet2manual.json';
import { procedureObject, Map } from '../interfacesUtil';

const procedureInformation: procedureObject = fleet2Manual;
const procedureLibraryId = procedureInformation.Id;
const procedureName = procedureInformation.Name;

@Component({
  selector: 'app-procedure',
  templateUrl: './procedure.component.html',
  styleUrls: ['./procedure.component.css'],
})
export class ProcedureComponent implements OnInit {
  displayNotFoundError = false;
  fleetNotFound = '';
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((data) => {
      const manualParamValue = data['manual'];
      var routeUrl = procedureInformation.ManualType[manualParamValue]
        ? this.getURL(procedureInformation.ManualType[manualParamValue])
        : null;
      if (routeUrl == null) {
        routeUrl = procedureInformation.OldManuals[manualParamValue]
          ? this.getOldURL(procedureInformation.OldManuals[manualParamValue])
          : null;
      }
      console.log(routeUrl);
      if (routeUrl != null) {
        this.redirect(routeUrl);
      } else {
        this.displayNotFoundError = true;
        this.fleetNotFound = manualParamValue;
      }
    });
  }

  redirect(routeUrl: string) {
    window.location.href = routeUrl;
  }

  getURL(publicationID: any) {
    let queryParams: Map = {
      library: procedureLibraryId,
      publicationID: publicationID,
    };
    const url = new URL(environment.basePath, environment.baseURL);
    const urlSearchParams = new URLSearchParams();
    Object.keys(queryParams).forEach((key) =>
      urlSearchParams.set(key, queryParams[key])
    );
    return url.toString() + '?' + urlSearchParams.toString();
  }

  getOldURL(oldManual: any) {
    let queryParams: Map = {
      VERSION: 'current',
      MODEL: procedureName,
      DOCTYPE: oldManual['DOCTYPE'],
      DOCNBR: oldManual['DOCNBR'],
      ISESSION: '1',
    };
    const url = new URL(environment.oldBasePath, environment.oldBaseURL);
    const urlSearchParams = new URLSearchParams();
    Object.keys(queryParams).forEach((key) =>
      urlSearchParams.set(key, queryParams[key])
    );
    return url.toString() + '?' + urlSearchParams.toString();
  }
}
