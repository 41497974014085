import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'angular-router-sample';
  fullUrl: string = '';
  constructor(private router: Router) {}


  ngOnInit() {
    // Initialize the full path URL
    this.fullUrl = this.router.url;

    // Listen for route changes and update the full path URL
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.fullUrl = window.location.origin +  this.router.url;
      }
    });
  }

}
